import { MicroAgent } from '@newrelic/browser-agent/loaders/micro-agent'

const getNewRelicAgent = (applicationID: string, licenseKey: string): MicroAgent => {
  const options = {
    info: {
      beacon: 'bam.nr-data.net',
      errorBeacon: 'bam.nr-data.net',
      licenseKey,
      applicationID,
      sa: 1,
    },
    loader_config: {
      accountID: '2429158',
      trustKey: '1106546',
      agentID: '1120304893',
      licenseKey,
      applicationID,
    },
    init: {
      distributed_tracing: { enabled: true },
      privacy: { cookies_enabled: true },
      ajax: { deny_list: ['bam.nr-data.net'] },
    },
  }

  return new MicroAgent(options)
}

export default getNewRelicAgent
