type LogMethod = (message: any, ...params: any[]) => void
type ErrorLogMethod = (message: any | Error, ...params: any[]) => void

export interface Logger {
  debug: LogMethod
  info: LogMethod
  log: LogMethod
  warn: LogMethod
  error: ErrorLogMethod
}

export enum LogLevel {
  TRACE = 0,
  DEBUG = 1,
  INFO = 2,
  WARN = 3,
  ERROR = 4
}

export const convertLogLevel = (logLevel: string | number): LogLevel => {
  if (typeof logLevel === 'number') {
    if (logLevel in LogLevel) {
      return logLevel as LogLevel
    } else {
      console.log(`ubik-logging: Invalid log level number: ${logLevel}`)
      return LogLevel.INFO
    }
  } else if (typeof logLevel === 'string') {
    const normalizedLogLevel = logLevel.toUpperCase()
    if (normalizedLogLevel in LogLevel) {
      return LogLevel[normalizedLogLevel as keyof typeof LogLevel]
    } else {
      console.log(`ubik-logging: Invalid log level string: ${logLevel}`)
      return LogLevel.INFO
    }
  } else {
    console.log(`ubik-logging: Invalid log level type: ${typeof logLevel}`)
    return LogLevel.INFO
  }
}

export class FragmentLogger implements Logger {
  protected logger: Logger
  protected name: string = 'default-logger-name'

  constructor (logger: Logger) {
    this.logger = logger
  }

  setLoggerName (name: string): void {
    this.name = name
  }

  postLog (level: LogLevel, message: any | Error, params: any[]): void {
    // no-op
  }

  debug (message: any, ...params: any[]): void {
    this.logger.debug(message, ...params)
    this.postLog(LogLevel.DEBUG, message, params)
  }

  log (message: any, ...params: any[]): void {
    this.logger.debug(message, ...params)
    this.postLog(LogLevel.DEBUG, message, params)
  }

  info (message: any, ...params: any[]): void {
    this.logger.info(message, ...params)
    this.postLog(LogLevel.INFO, message, params)
  }

  warn (message: any, ...params: any[]): void {
    this.logger.warn(message, ...params)
    this.postLog(LogLevel.WARN, message, params)
  }

  error (message: any | Error, ...params: any[]): void {
    this.logger.error(message, ...params)
    this.postLog(LogLevel.ERROR, message, params)
  }
}
