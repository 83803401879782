import { type MicroAgent } from '@newrelic/browser-agent/loaders/micro-agent'
import { FragmentLogger, LogLevel, convertLogLevel } from '../fragment-logger'
import { type Logger as LogLevelLogger } from 'loglevel'

export class MicroAgentLogger extends FragmentLogger {
  protected agent?: MicroAgent
  private readonly microAgentLogLevel: LogLevel

  constructor (logger: LogLevelLogger, agent: MicroAgent, microAgentLogLevel?: string | number) {
    super(logger)
    this.agent = agent
    this.microAgentLogLevel = convertLogLevel(microAgentLogLevel ?? 'error')
  }

  override postLog (level: LogLevel, message: any, params: any[]): void {
    if (level === LogLevel.ERROR) {
      this.agent?.noticeError(message, { parameters: { ...params }, name: this.name })
    }
    if (level < this.microAgentLogLevel) return

    this.agent?.log(message, { customAttributes: { parameters: { ...params }, name: this.name }, level: LogLevel[level] as any })
  }
}
