import { type LoggerOptions } from '@vp/ubik-fragment-types'
import { FragmentLogger, type Logger } from '../fragment-logger'

import getNewRelicAgent from './new-relic'
import { MicroAgentLogger } from './micro-agent-logger'
import log from 'loglevel'

export * from '../fragment-logger'

export function createLogger (options: LoggerOptions): Logger {
  let agent
  if (options.newrelicLicenseKey != null && options.newrelicApplicationId != null) {
    agent = getNewRelicAgent(options.newrelicApplicationId, options.newrelicLicenseKey)
  }

  const logLevelLogger = log.getLogger(options.serviceName)

  const logger = agent != null
    ? new MicroAgentLogger(logLevelLogger, agent, options.microAgentLogLevel)
    : new FragmentLogger(logLevelLogger)

  logger.setLoggerName(options.serviceName)
  return logger
}
